<template>
  <general-modal
      v-if="sharedReport"
      dialog-classes="modal-xl"
      :title="$t('report_share.title', { name: sharedReport.name })"
      @close="onClose"
  >
    <template #default>
      <form class="share-report-form view" @submit.prevent="onSubmit">
        <collapse>
          <template #header>
            <span data-toggle>{{ $t('report_share.share_as_email') }}</span>
          </template>

          <template #content>
            <form-configured-content
                :contents="reportConfigFields"
                :value="report.data"
                primary-file-preview-key="local"
                @input="onInput"
            />
          </template>
        </collapse>
        <div class="row align-items-center mb-2">
          <div class="col flex-grow-0">
            <div class="input-group">
              <ps-form-switch :value="usages !== null"
                              @input="onToggleFiniteUsages"
                              style="white-space: pre"
                              :label="$t('report_share.finite_usages')"/>
            </div>
          </div>
          <div class="col" v-if="usages !== null">
            <ps-form-input
                type="number"
                min-value="1"
                step="1"
                v-model.number="usages"
                :label="$t('report_share.number_of_usages')"
            />
          </div>
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <button type="submit"
                    class="btn btn-primary"
                    :disabled="loading"
            >
              {{ $t('report_share.create_link') }}
            </button>
          </div>

          <input type="text"
                 readonly
                 class="form-control h-100"
                 :value="sharedLink"
          >
          <div class="input-group-append"
               v-if="!loading && canCopyToClipboard">
            <button type="button"
                    class="btn btn-secondary"
                    @click="copyToClipboard(sharedLink)"
            >
              <i class="fa-solid fa-copy"></i>
            </button>
          </div>
        </div>
      </form>
    </template>
  </general-modal>
</template>

<script>

import Collapse from '@/components/Collapse.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal.vue'
import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import ApiError from '@pixelstein/api-store-utils/src/errors/ApiError.js'
import targetUrls from '@/mixins/target-urls.js'
import PsFormSwitch from '@pixelstein/ps-form/components/PsFormSwitch.vue'
import PsFormInput from '@pixelstein/ps-form/components/PsFormInput.vue'
import formConfig from '@/mixins/form-config'

export default {
  name: 'ReportShareModal',
  components: {
    GeneralModal, Collapse, FormConfiguredContent, PsFormSwitch, PsFormInput,
  },
  mixins: [targetUrls, formConfig],
  props: {
    reportConfigId: { type: String, default: null },
  },
  data () {
    return {
      sharedReport: null,
      loading: false,
      sharedLink: '',
      usages: null,
      report: {
        data: {
          email: null,
          subject: null,
        },
      },
      reportConfig: {
        fields: [
          {
            type: 'input',
            column: 'col-12',
            options: {
              type: 'email',
              name: 'email',
              label: this.$t('report_share.email'),
            },
            id: '3b7e7f10-cbe1-11ee-8447-c995068b845c',
          },
          {
            type: 'input',
            column: 'col-12',
            options: {
              type: 'text',
              name: 'subject',
              label: this.$t('report_share.subject'),
            },
            id: '48d828f0-cbe1-11ee-8447-c995068b845c',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      findReportConfig: 'Api/ReportConfigs/find',
    }),
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl,
    }),
    canCopyToClipboard () {
      return !!navigator.clipboard
    },
  },
  methods: {
    ...mapActions({
      shareReport: 'Api/ReportConfigs/share',
    }),
    onToggleFiniteUsages (value) {
      if (value) {
        this.usages = 1
      } else {
        this.usages = null
      }
    },
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (e) {
        console.log('Error copying url to clipboard', e)

        this.$toast.error(
            e.message,
            { position: this.$config.TOAST_POSITION },
        )
      }
    },
    async onClose () {
      window.localStorage.setItem("closeShareModal", 'true');
      await this.$router.back()
    },
    async onSubmit () {
      if (!this.checkMandatoryFields()) {
        this.$nextTick(
            () => this.$el.querySelector('.invalid')
                ?.scrollIntoView?.({ behavior: 'smooth', block: 'center' }))

        throw new ApiError(
            this.$t('report_share.check_mandatory_fields'),
            null,
            null,
            null,
            901,
        )
      }

      try {
        this.loading = true

        this.sharedLink = ''

        const target = this.targetUrlForRoute(
            { name: 'SharedReport', params: { token: '__TOKEN__', reportConfigId: '__ID__' } },
        )

        const res = await this.shareReport({
          id: this.reportConfigId,
          target,
          usages: this.usages,
          ...this.report.data,
        })

        this.sharedLink = res.target
      } finally {
        this.loading = false
      }
    },
    onInput (data) {
      this.report.data = data
    },
  },
  mounted () {
    this.sharedReport = this.findReportConfig(this.reportConfigId)
  },
}
</script>
